import React from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import { Link } from "gatsby"
import BreadcrumbSchema from "@components/schema/breadcrumb"

const StyledLink = styled(Link)``

const BreadcrumbContainer = styled.nav`
  display: flex;
  font-weight: bold;
  font-size: 85%;
  padding: ${({ noPadding }) => (noPadding ? `0` : `2rem 0 2rem 0`)};
  flex-wrap: wrap;
`

const Divider = styled.span`
  color: rgb(204, 204, 204);
  padding: 0px 8px;
`

const Breadcrumb = ({ links, noPadding = false }) => {
  const { color } = useTheme()

  return (
    <>
      <BreadcrumbSchema links={links} />
      <BreadcrumbContainer noPadding={noPadding} aria-label="breadcrumbs">
        {links.map((link, i) => {
          const comp = !link.url ? (
            <span key={`spanlink-${i}`}>{link.text}</span>
          ) : (
            <StyledLink
              style={{ color: color.blue400 }}
              key={`styledlink-${i}`}
              to={link.url}
            >
              {link.text}
            </StyledLink>
          )
          return i < links.length - 1 ? (
            <React.Fragment key={`fragment-${i}`}>
              {comp} <Divider key={`styleddivider-${i}`}>/</Divider>{" "}
            </React.Fragment>
          ) : (
            comp
          )
        })}
      </BreadcrumbContainer>
    </>
  )
}

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
  noPadding: PropTypes.bool,
}

export default Breadcrumb
