import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Html from "@components/html"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { ResourceGrid } from "@components/resources/resource-grid"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const KpiExample = ({ data, pageContext }) => {
  const kpi = data.kpi
  const image = kpi.image
  const imageUrl = image?.cdn

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      title={kpi.title}
      description={kpi.summary}
      seo={kpi.seo}
      body={kpi.body}
      date_updated={kpi.date_updated}
    >
      <article>
        <ResourceWrapper
          gap="6rem"
          margin="0 0 6rem"
          marginMd="0 0 4rem"
          marginSm="0 0 2rem"
        >
          <ResourceHeader
            kpi
            name={kpi.h1 ?? `${kpi.title} Metric`}
            alt={`${kpi.category.name} KPI Example - ${kpi.title} Metric`}
            subtitle={kpi.subtitle}
            image={image}
            newsletterCta
            ctaCategory={kpi.category.name}
            links={[
              { text: "Resources", url: "/resources" },
              {
                text: "KPI Examples",
                url: "/resources/kpi-examples",
              },
              {
                text: kpi.category.name,
                url: `/resources/kpi-examples/${kpi.category.slug}`,
              },
              {
                text: kpi.h1 ?? kpi.title,
              },
            ]}
          />
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                inherit
                parseElements
                html={kpi.body}
                images={kpi.body_images}
                cta
              />
            </Flex>
          </Container>
          {/*
            {kpi.related_examples && (
              <Container>
                <RelatedResourceGrid
                  title={kpi.related_title}
                  examples={kpi.related_examples}
                />
              </Container>
            )}
          */}
          {kpi.body2 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={kpi.body2}
                  images={kpi.body2_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {kpi.body3 && (
            <Container text>
              <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
                <Html
                  inherit
                  parseElements
                  html={kpi.body3}
                  images={kpi.body3_images}
                  cta
                />
              </Flex>
            </Container>
          )}
          {(kpi.show_related && !kpi.related_examples) && (
            <Container>
              <ResourceGrid
                kpi
                title={"Related"}
                related
                examples={pageContext.relatedExamples}
              />
            </Container>
          )}
        </ResourceWrapper>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KpiExample.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default KpiExample

export const pageQuery = graphql`
  query KpiExamplePageQuery($id: String!) {
    kpi: kpiExamples(id: { eq: $id }) {
      title
      subtitle
      category {
        name
        slug
      }
      status
      id
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      h1
      summary
      date_updated
      image {
        id
        title
        cdn
        placeholder
      }
      seo {
        ...seoData
      }
      show_related
    }
  }
`
