import styled, { css } from "styled-components"
import media from "../../styles/media"

export const LowGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -20%, 0);
  min-width: 2500px;
  z-index: 0;
  filter: blur(24px);
  height: auto;
  pointer-events: none;
`

export const MidGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate3d(-50%, 0%, 0);
  min-width: 1800px;
  z-index: 0;
  opacity: 0.7;
  filter: blur(12px);
  height: auto;
  pointer-events: none;
`

export const FollowGradient = styled.img`
  position: fixed;
  left: 50%;
  top: 70%;
  transform: ${props =>
    props.flip
      ? " translate3d(-50%, -30%, 0) scale(-1)"
      : " translate3d(-50%, -30%, 0) scale(1)"};
  min-width: 2200px;
  z-index: 0;
  filter: blur(12px);
  height: auto;
  transition: transform 2.8s ease-in-out;
  pointer-events: none;
`

export const HighGradient = styled.img`
  position: absolute;
  left: 50%;
  top: ${props => props.top || "0%"};
  transform: translate3d(-50%, -50%, 0);
  min-width: 2000px;
  z-index: 0;
  filter: blur(24px);
  height: auto;
  pointer-events: none;

  // write props to hide on md and mobile, md should hide both md and mobile
  ${({ hideOnMd }) =>
    hideOnMd &&
    css`
      ${media.md`
        display: none;
      `}
    `}
  ${({ hideOnSm }) =>
    hideOnSm &&
    css`
      ${media.sm`
        display: none;
      `}
    `}
`
