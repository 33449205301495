import React from "react"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Container from "@components/layout/container"
import Button from "@components/elements/button"
import Heading from "@components/elements/heading"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"
import { LowGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import Paragraph from "@components/elements/paragraph"
import { KlipsLogo } from "@components/common/logo"

export const KlipsEOPCta = ({ heading, description, padding, force }) => {
  const { color } = useTheme()
  const { gradient } = useGradient(true)

  return (
    <Div position="relative" background={color.blue700} overflow="hidden">
      <LowGradient src={gradient.cdn} loading="lazy" />
      <Container style={{ zIndex: "10", position: "relative" }}>
        <Flex
          center
          alignItems="center"
          padding={padding ?? "8rem 0"}
          gap="2rem"
        >
          <KlipsLogo height="60px" />
          <Heading color={"white"} fontSize="3rem" as="h2">
            {heading ?? (
              <>
                Build custom dashboards
                <br />
                for you and your team.
              </>
            )}
          </Heading>
          {description && (
            <Paragraph
              maxWidth="40ch"
              color="white"
              fontSize="1.4rem"
              lineHeight="130%"
            >
              {description}
            </Paragraph>
          )}
          <Button.Klips force={force} noticeColor="white" data-cta-location="eop" text />
        </Flex>
      </Container>
    </Div>
  )
}

KlipsEOPCta.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  padding: PropTypes.string,
  force: PropTypes.bool
}
